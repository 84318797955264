import { Navigate, Route, Routes } from "react-router-dom";
import { guestRoutes } from "../../constants/routes";

export const MainContainer = () => {
  return (
    <>
      <Routes>
        {guestRoutes.map(({ path, component }, i) => (
          <Route path={path} element={component} key={i} exact={true} />
        ))}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};

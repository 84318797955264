import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const Loader = () => (
  <LoaderWrapper className="flexCenter x100" style={{ margin: "auto" }}>
    <CircularProgress />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  min-height: 250px;
`;

import styled from "styled-components";
import { LogoSVG } from "../../assets/svg/Svgs";
import { Button } from "antd";
import { Segment } from "@mui/icons-material";
import { APP_URL } from "../../constants/constants";

export const AppBar = () => {
  return (
    <Wrapper className="x100">
      <InnerWrapper className="container flexSpaceCenter y100">
        {/* LOGO */}
        <img src={LogoSVG} height={40} />
        {/* MENU */}
        <MenuWrapper className="flexNullCenter font13 desktop">
          {navMenu.map((item) => (
            <MenuItem data={item} />
          ))}
        </MenuWrapper>

        {/* END */}
        <ActionWrapper className="flex gap20 desktop">
          <Button type="primary">Sign Up Today</Button>
          <Button href={APP_URL} target="_blank">
            Login
          </Button>
        </ActionWrapper>
        {/* MOBILE */}
        <OptionsButton className="mobile r10 flexCenter">
          <Segment />
        </OptionsButton>
      </InnerWrapper>
    </Wrapper>
  );
};

const MenuItem = ({ data }) => {
  const { label, path } = data || {};
  return (
    <a className="pointer black" href={path}>
      {label}
    </a>
  );
};

const navMenu = [
  {
    label: "Home",
    path: "#home",
  },
  {
    label: "Benefits",
    path: "",
  },
  {
    label: "Resource Centre",
    path: "",
  },
  {
    label: "Pricing",
    path: "",
  },
  {
    label: "FAQ",
    path: "#footer",
  },
];

const Wrapper = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  position: fixed;
  background-color: rgb(255 255 255 / 80%);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 1000;

  .mobile {
    display: none;
  }

  @media (max-width: 960px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
`;
const InnerWrapper = styled.div`
  position: relative;
  padding: 0 20px;
`;
const ActionWrapper = styled.div``;
const OptionsButton = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
`;
const MenuWrapper = styled.div`
  gap: 40px;
`;

import { createTheme } from "@mui/material";
import { primaryColor, secondaryColor } from "./colors";

export const antdTheme = {
  token: {
    colorPrimary: primaryColor,
    secondaryColor: secondaryColor,
    fontFamily: "DM Sans",
    borderRadius: 6,
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

import { ConfigProvider } from "antd";
import { antdTheme, muiTheme } from "./constants/theme";
import { BrowserRouter } from "react-router-dom";
import { MainContainer } from "./components/layouts/MainContainer";
import { ThemeProvider } from "@mui/material";

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <ConfigProvider theme={antdTheme}>
        <BrowserRouter>
          <MainContainer />
        </BrowserRouter>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;

import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { TabletView } from "../../../components/elements/TabletView";
import { ArrowForward } from "@mui/icons-material";
import { APP_URL } from "../../../constants/constants";

export const Header = () => {
  return (
    <Wrapper id="home">
      <div className="flexCenter flexColumn">
        <TitleWrapper className="flexColumn textCenter">
          {/* <h1 className="bold main" data-aos="fade-up" data-aos-duration="1500">
            {APP_NAME}.com
          </h1> */}

          <h1
            className="bold secondary"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1500"
          >
            Managing your Loan portfolio is now easier than sending{" "}
            <span className="main">Mobile Money</span>
          </h1>
          <div
            className="font13 grey"
            data-aos="fade-up"
            data-aos-delay="1000"
            data-aos-duration="1500"
          >
            Loan management software today are like a village rumor; details get
            lost so fast! <span className="main semiBold">Credtics.com</span>{" "}
            keeps every record safe and sound, just like a good bank vault.
            Track every loan—better than your mom keeping tabs on your WhatsApp
            last seen!
          </div>
          <div
            className="gap20 flexCenter"
            data-aos="fade-up"
            data-aos-delay="1500"
            data-aos-duration="1500"
          >
            <Button
              type="primary"
              style={{ width: 150 }}
              href={APP_URL + "register"}
              target="_blank"
              icon={<ArrowForward style={{ fontSize: 15 }} />}
            >
              Get Started
            </Button>
            <Button style={{ width: 150 }} href={APP_URL} target="_blank">
              Login
            </Button>
          </div>
        </TitleWrapper>
        {/* TABLET */}
        <TabletWrapper
          className="gap20 flexCenter"
          data-aos="fade-up"
          data-aos-delay="1500"
          data-aos-duration="1500"
        >
          <TabletView />
        </TabletWrapper>
      </div>
    </Wrapper>
  );
};

const TitleWrapper = styled.div`
  max-width: 600px;
  gap: 30px;

  h1 {
    margin-top: 60px;
  }

  @media (max-width: 960px) {
    padding: 0 15px;
  }
`;
const Wrapper = styled.div`
  padding-top: 70px;
  min-height: 100vh;
`;

const TabletWrapper = styled.div`
  width: -webkit-fill-available;
  margin: 50px 0;

  @media (max-width: 960px) {
    margin: 0;
    height: 460px;
  }
`;

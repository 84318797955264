import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

export const helpLinks = [
  {
    label: "Contact Us",
    path: "",
  },
  {
    label: "Customer Support",
    path: "",
  },
  {
    label: "Terms & Conditions",
    path: "",
  },
  {
    label: "Privacy Policy",
    path: "",
  },
];

export const resourceLinks = [
  {
    label: "Frequently Asked Questions",
    path: "",
  },
  {
    label: "Resource Center",
    path: "",
  },
];

export const socialLinks = [
  {
    icon: Facebook,
    path: "#",
  },
  {
    icon: Twitter,
    path: "#",
  },
  {
    icon: LinkedIn,
    path: "#",
  },
  {
    icon: Instagram,
    path: "#",
  },
];

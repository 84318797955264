import { IconButton } from "@mui/material";
import styled from "styled-components";
import { LogoSVG } from "../../../assets/svg/Svgs";
import { APP_EMAIL, APP_PHONE } from "../../../constants/constants";
import {
  helpLinks,
  resourceLinks,
  socialLinks,
} from "../../../constants/links";
import { getCurrentYear } from "../../../utils/helperUtils";

export const Footer = () => {
  return (
    <>
      <GridWrapper className="container x100">
        <img src={LogoSVG} height={40} />

        {/* SECTIONS */}
        <FooterSection links={helpLinks} title={"company"} />
        <FooterSection links={helpLinks} title={"help"} />
        <FooterSection links={resourceLinks} title={"resources"} />

        {/* CONTACT */}
        <div className="flexColumn gap20">
          <div className="secondary bold font13 uppercase">Reach Out</div>

          <div>
            <div className="font11 grey semiBold mb5">CALL US</div>
            <a className="bold secondary" href={`tel:${APP_PHONE}`}>
              {APP_PHONE}
            </a>
          </div>
          <div>
            <div className="font11 grey semiBold mb5">EMAIL US</div>
            <a className="bold secondary" href={`mailto:${APP_EMAIL}`}>
              {APP_EMAIL}
            </a>
          </div>
        </div>
      </GridWrapper>
      <Wrapper className="p20 container">
        <InnerWrapper className="flexSpaceCenter">
          <div className="grey font12">
            © Copyright {getCurrentYear()}. All Rights Reserved
          </div>
          <div className="flex">
            {socialLinks.map(({ icon: Icon, path }) => (
              <a href={path}>
                <IconButton>
                  <Icon fontSize="small" />
                </IconButton>
              </a>
            ))}
          </div>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const FooterSection = ({ links, title }) => {
  return (
    <div className="flexColumn gap10 font13" id="footer">
      <div className="secondary bold mb15 uppercase">{title}</div>
      {links?.map(({ label, path }) => (
        <div className="grey">
          <a href={path} className="grey">
            {label}
          </a>
        </div>
      ))}
    </div>
  );
};

const GridWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 80px 20px;

  @media (max-width: 960px) {
    gap: 40px;
    grid-template-columns: 1fr;
    padding: 20px;
  }
`;
const Wrapper = styled.div`
  border-top: 1px solid #eee;
`;
const InnerWrapper = styled.div`
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 15px;
  }
`;

import { Button } from "antd";
import React from "react";
import styled from "styled-components";

export const Banner = () => {
  return (
    <Wrapper className="secondaryBg patternGradient">
      <div className="container white flexCenter textCenter flexColumn gap20 p20 y100">
        <div className="font30 bold ">
          Join <span className="main">Credtics.com</span> today and elevate your
          customer experience!
        </div>
        <div className="font13 mb20">
          Boost satisfaction, streamline loan processes, and build lasting
          loyalty with your clients.
        </div>
        <Button type="primary" style={{ height: 45 }}>
          Get Started Now
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 400px;
`;

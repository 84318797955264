import React from "react";
import { Header } from "./sections/Header";
import { AppBar } from "../../components/appbar/AppBar";
import { Footer } from "./sections/Footer";
import { Banner } from "./sections/Banner";

export const Landing = () => {
  return (
    <>
      <AppBar />
      <Header />
      <Banner />
      <Footer />
    </>
  );
};

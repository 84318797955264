import {
  AccountBalanceWallet,
  ArchiveOutlined,
  ArrowBack,
  CalendarMonthOutlined,
  CardTravelOutlined,
  CreditCard,
  Receipt,
  Search,
} from "@mui/icons-material";
import styled from "styled-components";
import { Button, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { Loader } from "../common/Loader";
import { primaryColor } from "../../constants/colors";
import { TabSVG } from "../../assets/svg/Svgs";

export const TabletView = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 3000);
  }, []);

  return (
    <TabletWrapper>
      <ImgWrapper src={TabSVG} />
      <ScreenWrapper className="flexColumn gap20">
        {visible ? (
          <>
            <div data-aos="fade-down" data-aos-duration="1000">
              <Input
                className="radius10"
                placeholder="Search"
                prefix={<Search className="grey" fontSize="small" />}
                style={{ height: 35 }}
              />
            </div>
            <ScreenGrid className="flexGrow">
              <LeftGrid>
                {screenItems.map((item, i) => (
                  <WideTile index={i}>{item.label}</WideTile>
                ))}
                <div
                  style={{ height: 0 }}
                  data-aos="fade-up"
                  data-aos-delay="1400"
                  data-aos-duration="1500"
                >
                  <Button
                    type="primary"
                    className="x100"
                    icon={<ArrowBack style={{ fontSize: 15 }} />}
                  >
                    Apply for Loan
                  </Button>
                </div>
              </LeftGrid>
              <RightGrid>
                {screenItems.map((item, i) => (
                  <IconTile data={item} index={i} />
                ))}
              </RightGrid>
            </ScreenGrid>
          </>
        ) : (
          <Loader />
        )}
      </ScreenWrapper>
    </TabletWrapper>
  );
};

const WideTile = ({ children, index }) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay={index * 500}
      data-aos-duration="1500"
    >
      <TileWrapper className="bold capitalize flexSpaceCenter r10 animate y100">
        {children}
        <Switch defaultChecked={index & 1 ? true : false} />
      </TileWrapper>
    </div>
  );
};

const IconTile = ({ data: { label, icon: Icon }, index }) => {
  const delay = (index + 1) * 200;
  return (
    <div data-aos="fade-left" data-aos-delay={delay} data-aos-duration="1500">
      <TileWrapper className="capitalize flexColumn gap10 flexCenter r10 animate y100">
        <Icon id="tileIcon" className="animate" />
        <div className="textCenter">
          <div className="bold">{label}</div>
          <div className="font11 grey">Track {label}</div>
        </div>
      </TileWrapper>
    </div>
  );
};

const screenItems = [
  {
    label: "Loans",
    icon: AccountBalanceWallet,
  },
  {
    label: "applications",
    icon: ArchiveOutlined,
  },
  {
    label: "reciepts",
    icon: Receipt,
  },
  {
    label: "schedules",
    icon: CalendarMonthOutlined,
  },
  {
    label: "transactions",
    icon: CardTravelOutlined,
  },
  {
    label: "payments",
    icon: CreditCard,
  },
];

const TabletWrapper = styled.div`
  position: relative;
  @media (max-width: 960px) {
    scale: 0.5;
  }
`;

const ScreenWrapper = styled.div`
  overflow: hidden;
  padding: 20px;
  position: absolute;
  background-color: #f2f2f2;
  border-radius: 10px;

  left: 25px;
  top: 47px;
  bottom: 43px;
  width: 734px;

  @media (max-width: 960px) {
  }
`;

const ScreenGrid = styled.div`
  height: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 2fr;
  @media (max-width: 960px) {
  }
`;

const LeftGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  @media (max-width: 960px) {
  }
`;

const RightGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 960px) {
  }
`;

const ImgWrapper = styled.img`
  width: 800px;
  position: relative;
  @media (max-width: 960px) {
  }
`;

const TileWrapper = styled.div`
  background: white;
  padding: 0 20px;
  font-size: 13px;

  &:hover {
    scale: 1.04;
    color: ${primaryColor};
    box-shadow: rgb(0 0 0 / 35%) 0 40px 80px;
    #tileIcon {
      font-size: 50px;
    }
  }

  #tileIcon {
    font-size: 40px;
    color: ${primaryColor};
  }

  @media (max-width: 960px) {
  }
`;
